<template>
  <div class="bakkk">
    <!--chilrdrn大模块  左侧制作-->
    <div class="chilrdrn-left">
      <div class="detail">
        <!--歌曲名称-->
        <div class="miaoshu">
          <div class="miaoshu_up">
            <el-tooltip class="miaoshu_txt" effect="light" :content="$t('musicaigequtitle')" placement="right">
              <el-button class="bbttnn">{{$t('musicaigequgett')}}</el-button>
            </el-tooltip>
          </div>
          <el-input class="miaoshu_input" type="text" :autosize="{ minRows: 2, maxRows: 4}" :placeholder="$t('musicaigequgecittinput')" v-model="title">
          </el-input>
        </div>
        <!--歌词-->
        <div class="miaoshu">
          <div class="miaoshu_up">
            <el-tooltip class="miaoshu_txt" effect="light" :content="$t('musicaigequgeci')" placement="right">
              <el-button class="bbttnn">{{$t('musicaigequgegeci')}}</el-button>
            </el-tooltip>
          </div>
          <div v-loading="loading"  element-loading-text="loading...">
            <el-input class="miaoshu_input" type="textarea" :autosize="{ minRows: 12, maxRows: 18}" :placeholder="$t('musicaigequgeciinput')" v-model="lyric">
            </el-input>
          </div>
          <div  class="miaoshu_down">
            <el-button @click.stop="generateLyrics()" icon="el-icon-paperclip" type="warning" class="random_btn" style="padding-right: 5px" round>
              <span class="random_btn" >{{$t('musicaigequgemakegeci')}}</span>
            </el-button>
          </div>
        </div>
        <!--音乐风格-->
        <div class="miaoshu">
          <div class="miaoshu_up">
            <el-tooltip class="miaoshu_txt" effect="light" :content="$t('musicaigequfenggei')" placement="right">
              <el-button class="bbttnn">{{$t('musicaigequstyle')}}</el-button>
            </el-tooltip>
          </div>
          <div v-if="currentId=='-1'">
            <el-input class="miaoshu_input" type="text"  :placeholder="$t('musicaigequstyleinput')" v-model="style">
            </el-input>
          </div>
          <div v-else>
            <el-input class="miaoshu_input" >
              <template type="textarea" :autosize="{ minRows: 2, maxRows: 4}" slot="prefix">
                <el-tag style="margin: 5px"  :color="color" type="info" effect="dark" closable @close="closetag()">{{label}}</el-tag>
              </template>
            </el-input>

          </div>
          <div  class="miaoshu_down">
            <el-tag @click="clickStyle(item,index)" :color="item.color" style="margin: 5px;cursor: pointer;"  v-for="(item, index) in items" :key="index" type="info" effect="dark">
              {{ item.label }}
            </el-tag>
          </div>

        </div>

        <!--歌曲描述模块-->
        <!--      <div class="miaoshu">-->
        <!--        <div class="miaoshu_up">-->
        <!--          <el-tooltip class="miaoshu_txt" effect="light" :content="$t('musicaigequmiaoshutt')" placement="right">-->
        <!--            <el-button class="bbttnn">{{$t('musicaigequmiaoshu')}}</el-button>-->
        <!--          </el-tooltip>-->
        <!--        </div>-->
        <!--        <el-input class="miaoshu_input" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :placeholder="$t('musicaigequinput')" v-model="idea">-->
        <!--        </el-input>-->
        <!--      </div>-->
        <!--纯音乐-->
        <!--      <div class="chunyinyue">-->
        <!--        <div class="chunyinyue_up">-->
        <!--          <el-switch class="chunyinyue_switch" v-model="makeInstrumental"></el-switch>-->
        <!--        </div>-->
        <!--        <el-tooltip class="chunyinyue_tishi" effect="light" :content="$t('musicaichunyinyuett')" placement="right">-->
        <!--          <el-button class="bbttnn">{{$t('musicaichunyinyue')}}</el-button>-->
        <!--        </el-tooltip>-->
        <!--      </div>-->
        <!--积分详情-->
        <div class="wode">
          <span class="wodd bbttnn">{{$t('musicaiwodejifen')}}：{{myintegral}}</span>
          <el-divider class="wodd" direction="vertical"></el-divider>
          <span class="wodd bbttnn">{{$t('musicaixiaohaojifen')}}</span>
        </div>
        <!--按钮-->
        <div class="anniu">
          <el-button @click.stop="make()" icon="el-icon-s-help" style="width: 100%" medium="medium" type="warning">{{$t('musicaibuttonchuangzuo')}}</el-button>
        </div>
      </div>
    </div>
    <!--chilrdrn大模块  右侧列表-->
    <div class="chilrdrn-right">
      <el-scrollbar class="detail_list"  wrap-style="overflow-x:hidden;">
        <div class="mlist" v-for="(item,index) in tableData" :key="index">
          <!-- 假状态 -->
          <div class="mlist_loding" v-if="item.music_loading == 'wait'">
            <i  class="el-icon-loading" style="margin-right: 10px;font-size: 30px"></i>AI创作中，正在排队...
          </div>
          <div class="mlist_loding" v-else-if="item.music_loading == 'online'">
            <el-progress style="width:400px" :text-inside="true" :stroke-width="20" :percentage="item.percentage" status="success"></el-progress>
          </div>
          <div class="mlist_loding" v-else-if="item.music_loading == 'fail'">
            <i  class="el-icon-hot-water" style="margin-right: 10px;font-size: 30px"></i>AI创作失败...
          </div>
          <div class="mlist_box" v-else>
            <div class="mlist_img">
              <music-ai-icon :type="'reference'" @xiazai="xiazai" @geci="geci" :obj="item.music" @showLyric="showLyric"></music-ai-icon>
              <div class="mlist_desc">
                <div class="disc_title">{{item.music.title}}</div>
                <div class="disc_label">{{item.label}}</div>
              </div>
            </div>
            <div class="mlist_btn">
              <div class="btn_geci" @click="geci(item.music)">{{$t('musicaichakangeci')}} <i class="el-icon-view" /></div>
              <div class="btn_xz" @click="xiazai(item.music)">{{$t('musicaixiazaiyinyue')}} <i class="el-icon-download" /></div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <!-- 右侧多余的歌词模块-->
    <div class="chilrdrn-geci" v-show="geci_alert">
      <el-scrollbar style="height: 100%" class="geci_list"  wrap-style="overflow-x:hidden;" >
        <div class="notification__group">
          <div class="notification__content">
            <p style="white-space: pre-wrap;"  v-html="geci_content"></p>
          </div>
          <div @click="close_geci_alert()" class="el-notification__closeBtn el-icon-close"></div>
        </div>
      </el-scrollbar>

    </div>
  </div>
</template>

<script>
import MusicAiIcon from "@/components/content/MusicAIIcon";
import {
  aiStyleDataBase,
  generateLyrics,
  getLiking, integralDataBase, musicAIGet, musicAIGetList,
  musicAIMake,
} from "@/api/Mine";
export default {
  name: "MusicAI",
  components: {
    MusicAiIcon,
  },
  data() {
    return {
      geci_content:'',
      geci_alert:false,
      myInfo:{},
      myintegral:0,
      loading: false,
      makeInstrumental:false,
      idea:'',
      currentId:'-1',
      color:'#222426',
      lyric:'',
      style:'',
      label:'',
      title:'',
      items: [],

      percentage:0,
      dialogVisible: false,
      visible: false,
      gecishow: false,
      tableData: [],
    }
  },
  created() {
    this.aiStyleDataBasefunc(); //音乐风格基础
    this.getMyMusicAiList();
  },
  updated() {
    this.myintegral = JSON.parse(localStorage.getItem('myInfo')).integral
  },
  methods: {
    // 积分基础套餐
    aiStyleDataBasefunc() {
      let lang = localStorage.getItem('lang')
      this.lang = lang
      aiStyleDataBase({'lang':lang}).then((res) => {
        this.items = res.data
      }).catch((err) => {
            console.log(err, '基础信息失败');
      });
    },
    clickStyle(e, index){
      console.log(e,index)
      this.color = e.color
      this.style = e.style
      this.label = e.label
      this.currentId = index
    },
    closetag(){
      this.color = '#222426'
      this.style = ''
      this.label = ''
      this.currentId = -1
    },
    //生成歌词
    generateLyrics(){
      this.loading = true
      generateLyrics({
        idea: this.lyric,
      }).then((res) => {
        if (res.status == 1) {
         // this.idea = res.data.lyric
         this.lyric = res.data.lyric
          this.title = res.data.title
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err, '歌词失败');
      });
    },
    make() {
      if(this.lyric.length <= 0 || this.style.length <= 0){
        this.$message({
          message: '歌词与风格不要忘记填写呦',
          type: 'warning'
        });
        return
      }
      musicAIMake({
        lyric: this.lyric,
        style: this.style,
        label: this.label,
        title: this.title,
      }).then((res) => {
        console.log(res, '开始创作');
        if (res.status == 1) {
          this.$message.success(res.msg)
          this.myintegral = this.myintegral - 10
          setTimeout(() => {
            this.$router.go(0) && location.reload();
          }, 1400)

        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
            console.log(err, '创作失败');
          });
    },


    // 获取我的作品列表
    getMyMusicAiList() {
      musicAIGetList({}).then((res) => {
        if (res.status == 1) {
          //当最新的作曲还在创作时候，轮询查询，直到接口返回repeat=0
          if(res.repeat == 1){
            res.data.forEach((item) => {
              if(item.music_loading == 'online'){
                let jia_process = localStorage.getItem("process_" + item.task_id);
                if(jia_process){
                  let new_jia_process = Number(jia_process) + Number(5)
                  if(new_jia_process >= 100){
                    localStorage.setItem("process_" + item.task_id, 99);
                    item.percentage = 99
                  }else{
                    localStorage.setItem("process_" + item.task_id, new_jia_process);
                    item.percentage = new_jia_process
                  }
                }else{
                  localStorage.setItem("process_" + item.task_id, 1);
                  item.percentage = 1
                }
              }
            });
            setTimeout(this.getMyMusicAiList, 5000);
          }
          this.tableData = res.data;
        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log(err, '获取失败');
      });
    },
    // 下载音频
    xiazai(data) {
      let url = data.url;
      let title = data.title;
      console.log('下载音频', url)

      const a = document.createElement('a')
      a.href = url
      a.download = title // 下载后文件名
      a.style.display = 'none'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click() // 点击下载
      document.body.removeChild(a) // 下载完成移除元素

    },
    // 歌词
    geci(data) {
      this.geci_content = data.lyric.replace(/\n/g, '<br />');
      this.geci_alert = true;
      return;
      this.$notify({
        // title: data.title,
        offset: 80,
        dangerouslyUseHTMLString:true,
        message: data.lyric.replace(/\n/g, '<br />'),
        duration: 0
      });
    },
    //关闭歌词弹窗（新）
    close_geci_alert(){
      this.geci_alert = false;
      this.geci_content = '';
    },
    showLyric(val){
      this.geci(val);
    },
  },
}
</script>

<style lang='less'>
  /* 右侧弹出框---歌词 */
  .el-notification {
    background-color: @recommendMusicianListBGColor;
    border: none;
  }
  .el-notification__content {
    color: @headerSelectTextColor;
  }
  .el-notification__closeBtn {
    color: @headerSelectTextColor;
  }
</style>

<style scoped  lang='less'>
  .bbttnn {
    color: @headerSelectTextColor  !important;
    background-color: @baseBackgroundColor;
    font-weight: bold;
  }

  /* input 背景色 */
  ::v-deep .el-input__inner {
    border: 1px solid @popupLockTimeBorderColor;
    color: @headerSelectTextColor  !important;
    background-color: @recommendMusicianListBGColor;
    &:hover {
      border: 1px solid @popupCancelTextColor;
    }
  }
  /* 歌词textarea 背景色 */
  ::v-deep .el-textarea__inner {
    border: 1px solid @popupLockTimeBorderColor;
    resize: none;
    color: @headerSelectTextColor  !important;
    background-color: @recommendMusicianListBGColor;
    &:hover {
          border: 1px solid @popupCancelTextColor;
    }
  }
  /* 歌词textarea 加载中的 背景色 */
  ::v-deep .el-loading-mask {
    background-color: @aigecijiazaiBackgroundColor;
  }
  ::v-deep .el-loading-spinner .el-loading-text {
    color: @headerSelectTextColor  !important;
  }
  /* 歌词textarea   滚动条*/
  ::v-deep .el-textarea__inner::-webkit-scrollbar{
    width: 6px ;
    height: 6px ;
  }
  ::v-deep .el-textarea__inner::-webkit-scrollbar-thumb {
    border-radius: 3px ;
    -moz-border-radius: 3px ;
    -webkit-border-radius: 3px ;
    background-color: #c3c3c3 ;
  }
  ::v-deep .el-textarea__inner::-webkit-scrollbar-track {
    background-color: transparent ;
  }

  .bakkk {
    display: flex;
    flex-direction: row;
    .chilrdrn-left{
      display: flex;
      width: 40%;
    }
    .chilrdrn-right{
      display: flex;
      width: 60%;
      margin-left: 20px;
    }
    .chilrdrn-geci{
      right: 20px;
      top: 98px;
      position: absolute;
      display: flex;
      width: 330px;
      height: 880px;
    }
  }

  /* 左侧制作 */
  .detail {
    display: flex;
    flex-direction: column;
    .miaoshu {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .miaoshu_up {
        display: flex;
        flex-direction: row;
        align-content: center;
        //margin-bottom: 10px;
        margin-top: 10px;
        .miaoshu_txt {
          font-size: 14px;
          border: none;
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: center;
        }
      }
      .miaoshu_down {
        display: flex;
        flex-direction: row;
        align-content: center;
        margin: 5px;
        flex-wrap: wrap;
      }
      .miaoshu_input {
        display: flex;
      }
      .random_btn {
        font-size: 14px;
        padding: 5px 5px;
        //display: inline-block;
        margin-top: 5px;
        cursor: pointer;
        //width: 100px;
      }
    }

    .chunyinyue {
      display: flex;
      flex-direction: row;
      align-content: center;
      padding-top: 10px;
      padding-bottom: 10px;
      .chunyinyue_up {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .chunyinyue_switch {
          display: flex;
          align-content: center;
          justify-content: center;
          margin-right: 10px;
        }
      }
      .chunyinyue_tishi {
        font-size: 12px;
        border: none;
        height: 10px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
      }
    }
    .wode {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      .wodd {
        display: flex;
        font-size: 15px;
        margin: 10px;
      }
    }
    .anniu {
      display: flex;
      margin-top: 25px;
    }
  }

  /* 右侧列表 */
  .detail_list {
    display: flex;
    flex-direction: column;
    width:100%;
    height: 888px;
    overflow-x: hidden;
    margin-top: 20px;
    border-radius: 10px;
    color: @headerSelectTextColor  !important;
    .mlist {
      display: flex;
      .mlist_loding {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 10px;
        width: 100%;
        height: 80px;
        background-color: @recommendMusicianListBGColor;

      }
      .mlist_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 10px;
        width: 100%;
        background-color: @recommendMusicianListBGColor;
        .mlist_img {
          display: flex;
          .mlist_desc {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .disc_title {
              display: flex;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .disc_label {
              display: flex;
              font-size: 12px;
            }
          }
        }
        .mlist_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          .btn_geci {
            padding: 10px;
            display: flex;
            cursor: pointer;
          }
          .btn_xz {
            margin-left: 10px;
            padding: 10px;
            display: flex;
            cursor: pointer;
          }
        }
      }
    }
  }

  .geci_list {

    display: flex;
    flex-direction: column;
    width:100%;
    overflow-x: hidden;
    margin-top: 20px;
    border-radius: 10px;
    color: @headerSelectTextColor  !important;
    background-color: @recommendMusicianListBGColor;
    //display: flex;
    //width:100%;
    //overflow-x: hidden;
    //border-radius: 10px;
    //color: @headerSelectTextColor  !important;
    //background-color: @recommendMusicianListBGColor;


    //padding: 20px;
    .notification__group{margin-top: 10px;margin-left:15px;margin-right:8px}
    .notification__content{font-size:14px;line-height:21px;margin:6px 0 0;text-align:justify;margin-top: 40px}
    .notification__content p{margin:0}
  }


</style>