import { render, staticRenderFns } from "./MusicaiMake.vue?vue&type=template&id=033d9350&scoped=true"
import script from "./MusicaiMake.vue?vue&type=script&lang=js"
export * from "./MusicaiMake.vue?vue&type=script&lang=js"
import style0 from "./MusicaiMake.vue?vue&type=style&index=0&id=033d9350&prod&lang=less"
import style1 from "./MusicaiMake.vue?vue&type=style&index=1&id=033d9350&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033d9350",
  null
  
)

export default component.exports